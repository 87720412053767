// extracted by mini-css-extract-plugin
export var fadeIn = "f_G";
export var featuresSection = "f_L";
export var featuresSectionImage = "f_M";
export var headSection = "f_D";
export var headSectionImage = "f_H";
export var headSectionLogo = "f_F";
export var icons = "f_P";
export var relatedProductsSection = "f_N";
export var textSection = "f_J";
export var textSectionVideo = "f_K";